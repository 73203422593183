@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~xp.css/dist/XP.css";

@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400&display=swap");

@layer base {
  html {
    font-family: "NeueHaasDisplay", system-ui, sans-serif;
  }

  .btn {
    @apply bg-black inline-block text-white py-2 px-4 rounded-md font-semibold hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-black hover:cursor-pointer;
  }
}
a:active,
a:focus {
  outline: none;
  border: none;
  -moz-outline-style: none;
}

a:-webkit-any-link {
  outline: none !important;
}

::-webkit-scrollbar {
  width: unset;
}
::-webkit-scrollbar-corner {
  background: var(--button-face);
}
::-webkit-scrollbar {
  width: 16px;
  &:horizontal {
    height: 17px;
  }
}
.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.title-bar {
  padding: 20px 20px 20px 20px;
}

label {
  @apply relative font-mono !important;
}

input {
  @apply border border-black;
}

textarea {
  @apply border border-black !important;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* footer {
  background-color: black;
  padding: 20px;
  left: 0;
  bottom: 0;
  color: white;
  width: 100%;
  position: fixed;

  
} */
.footer-text {
  margin-left: auto;
  margin-right: 20px;
  font-size: small;
}

.wish-header {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}

.social-icons {
  grid-column-start: 2;
}

.icon-link {
  margin: 0 10px;
  color: white;
  font-size: 100%;
}

.container .btn-custom {
  @apply bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700;
}

/* .logo-container {
  width: 32%;
  height: 40%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
 
} */

.tail {
  width: 92;
  height: 300;
}

.nav-link {
  cursor: pointer;
  font-size: 30px;
  text-decoration: none;
  color: black;
  transition: color 0.3s; /* Smooth transition effect */
  font-family: "NeueHaasDisplayRoman", sans-serif;
}

.nav-link:hover {
  color: blue;
}
a:active,
a:focus {
  outline: none;
  border: none;
  -moz-outline-style: none;
  box-shadow: none;
}

.nav-link a {
  text-decoration: none;
  color: inherit;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 25%;
  margin-left: auto;
  margin-top: 20px;
}

.dice-container {
  width: 25%;
  margin: 0 auto; /* Center the container horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh; /* 25% of the viewport height */
}

.dice-container img {
  max-width: 100%;
  max-height: 100%;
}

.h2 {
  font-family: "NeueHaasDisplayBold", sans-serif;
}

.h1-alt1 {
  font-family: "NeueHaasDisplay", sans-serif;
  font-size: 130px;
  color: rgb(0, 0, 0);
}

.h1-alt2 {
  font-family: "Sequel Sans";
  font-size: 130px;
  padding-bottom: 5px;
}

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: "Inconsolata", sans-serif;
  font-weight: 200px;
  overflow: hidden;
}

p {
  font-family: "Inconsolata", sans-serif;
  font-weight: 200px;
}

.h3 {
  font-family: "Inconsolata", "monospace";
  font-weight: 300;
  font-size: 30px;
}

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video {
  margin: 20px;
}

.container {
  width: 100%;
  height: 40px;
  margin: 0 auto;
}

/* Hidden for mobile browsers smaller than 768px */

.animation-container {
  height: 200px;
  width: 99%;
}

.ball {
  width: 40px;
  height: 40px;
  position: relative;
  top: 0%;
  left: 10%;
  margin-left: -20px;
  background: rgb(222, 21, 21);
  border-radius: 50%;
  animation: ball-fall 0.6s ease-in infinite alternate,
    ball-right 10s linear infinite;
}

@keyframes ball-fall {
  0% {
    top: 0%;
  }
  100% {
    top: 100%;
  }
}
@keyframes ball-right {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

@font-face {
  font-family: "NeueHaasDisplay";
  src: url("fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayThin.ttf")
    format("truetype");
}

@font-face {
  font-family: "NeueHaasDisplayRoman";
  src: url("fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayRoman.ttf")
    format("opentype");
}

@font-face {
  font-family: "Sequel Sans";
  src: url("fonts/Sequel Sans OTF/Sequel Sans Bold Body.otf") format("opentype");
}

/* Dark Mode Styles */
@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(255, 255, 255);
  }
}
